import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Button,
  Container,
  OutlinedInput,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import UserRepository from "../../../data/repository/UserRepository";
import User from "../../../core/entity/User";
import ErrorDialog from "../ErrorDialog";
import { Trans, useTranslation } from "react-i18next";

const RegisterForm = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const googleUser = location.state?.user;
  const googleToken = location.state;
  const [formData, setFormData] = useState({
    name: googleUser?.name ?? "",
    surname: googleUser?.lastName ?? "",
    email: googleUser?.email ?? "",
    phone: "",
    gender: "",
    birthdate: "",
    residence: "",
    cp: "",
    country: "",
    city: "",
    password: "",
    confirmPassword: "",
    bankAccount: "",
    bankAlias: "",
    cryptoWallet: "",
    cryptoNet: "",
    token: googleToken?.user?.token ?? "",
  });
  const [fieldErrors, setFieldErrors] = useState({});
  const [error, setError] = useState(null);
  const [terms, setTerms] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const navigate = useNavigate();
  const repository = new UserRepository();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = new User(formData);
    let validationErrors = user.validateUser(
      formData,
      googleToken?.user?.token
    );

    if (Object.keys(validationErrors).length > 0) {
      setFieldErrors(validationErrors);
      return;
    }

    if (!terms) {
      setError("Debes aceptar los términos y condiciones");
      return;
    }

    try {
      setFieldErrors({});
      const response = await repository.register(user);

      if (response.success) {
        setError(null);
        await navigate("/", {
          state: {
            showMessage: true,
            message:
              "Te hemos enviado un correo electrónico con un enlace de activación. Por favor, revisa tu correo para activar la cuenta",
          },
        });
      } else {
        setError(
          response.message === "register.error.00001"
            ? t("registerForm.noValidPhone")
            : response.message === "register.error.00002"
            ? t("myProfile.error2")
            : response.message === "register.error.00003"
            ? t("myProfile.error3")
            : "Error: " + response.message
        );
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleCheckboxChange = (event) => {
    setTerms(event.target.checked);
  };

  return (
    <Container sx={{ marginBottom: "2rem" }}>
      <Typography
        variant="h3"
        style={{ color: "#F36E1E", fontSize: "35px", fontWeight: "bold" }}
        align="center"
        mt={10}
        mb={12}
      >
        {t("registerForm.title")}
      </Typography>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t("registerForm.name")}
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              InputProps={{ style: { borderRadius: "55px" } }}
              helperText={t(fieldErrors.name)}
              error={Boolean(fieldErrors.name)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t("registerForm.surname")}
              name="surname"
              value={formData.surname}
              onChange={handleInputChange}
              InputProps={{ style: { borderRadius: "55px" } }}
              helperText={t(fieldErrors.surname)}
              error={Boolean(fieldErrors.surname)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel sx={{ background: "white" }}>
                {t("registerForm.gender")}
              </InputLabel>
              <Select
                name="gender"
                value={formData.gender}
                onChange={handleInputChange}
                input={
                  <OutlinedInput
                    style={{
                      borderRadius: "55px",
                    }}
                  />
                }
                helperText={t(fieldErrors.gender)}
                error={Boolean(fieldErrors.gender)}
                label={t("registerForm.gender")}
              >
                <MenuItem value={"male"}>{t("registerForm.male")}</MenuItem>
                <MenuItem value={"female"}>{t("registerForm.female")}</MenuItem>
                <MenuItem value={"undefined"}>
                  {t("registerForm.undefined")}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="date"
              label={t("registerForm.birthdate")}
              name="birthdate"
              value={formData.birthdate}
              onChange={handleInputChange}
              InputLabelProps={{ shrink: true }}
              InputProps={{ style: { borderRadius: "55px" } }}
              helperText={
                fieldErrors.birthdate
                  ? typeof fieldErrors.birthdate === "string"
                    ? t(fieldErrors.birthdate)
                    : `${t("registerForm.minAge1")} ${
                        fieldErrors.birthdate
                      } ${t("registerForm.minAge2")}`
                  : null
              }
              error={Boolean(fieldErrors.birthdate)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t("registerForm.address")}
              name="residence"
              value={formData.residence}
              onChange={handleInputChange}
              InputProps={{ style: { borderRadius: "55px" } }}
              helperText={t(fieldErrors.residence)}
              error={Boolean(fieldErrors.residence)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t("registerForm.zip")}
              name="cp"
              value={formData.cp}
              onChange={handleInputChange}
              InputProps={{ style: { borderRadius: "55px" } }}
              helperText={t(fieldErrors.cp)}
              error={Boolean(fieldErrors.cp)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t("registerForm.country")}
              name="country"
              value={formData.country}
              onChange={handleInputChange}
              InputProps={{ style: { borderRadius: "55px" } }}
              helperText={t(fieldErrors.country)}
              error={Boolean(fieldErrors.country)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t("registerForm.city")}
              name="city"
              value={formData.city}
              onChange={handleInputChange}
              InputProps={{ style: { borderRadius: "55px" } }}
              helperText={t(fieldErrors.city)}
              error={Boolean(fieldErrors.city)}
            />
          </Grid>

          {!googleUser && (
            <>
              <Grid item xs={12} sm={6} sx={{ position: "relative" }}>
                <VisibilityIcon
                  onClick={() => {
                    setShowPassword1(!showPassword1);
                  }}
                  sx={{
                    cursor: "pointer",
                    zIndex: "2",
                    position: "absolute",
                    top: "39px",
                    right: "24px",
                    color: "#F36E1E",
                  }}
                />
                <TextField
                  fullWidth
                  type={showPassword1 ? "text" : "password"}
                  label={t("registerForm.password")}
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  InputProps={{ style: { borderRadius: "55px" } }}
                  helperText={
                    fieldErrors.password
                      ? typeof fieldErrors.password === "string"
                        ? t(fieldErrors.password)
                        : `${t("registerForm.password1")} ${
                            fieldErrors.password
                          } ${t("registerForm.password2")}`
                      : null
                  }
                  error={Boolean(fieldErrors.password)}
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ position: "relative" }}>
                <VisibilityIcon
                  onClick={() => {
                    setShowPassword2(!showPassword2);
                  }}
                  sx={{
                    cursor: "pointer",
                    zIndex: "2",
                    position: "absolute",
                    top: "39px",
                    right: "24px",
                    color: "#F36E1E",
                  }}
                />
                <TextField
                  fullWidth
                  type={showPassword2 ? "text" : "password"}
                  label={t("registerForm.confPassword")}
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  InputProps={{ style: { borderRadius: "55px" } }}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="email"
              label={t("registerForm.email")}
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              InputProps={{ style: { borderRadius: "55px" } }}
              helperText={t(fieldErrors.email)}
              error={Boolean(fieldErrors.email)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={t("registerForm.phone")}
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              InputProps={{ style: { borderRadius: "55px" } }}
              helperText={t(fieldErrors.phone)}
              error={Boolean(fieldErrors.phone)}
            />
            <Typography
              variant="h3"
              style={{ margin: "0.25rem", fontSize: "13px" }}
            >
              {t("registerForm.phoneText")}
            </Typography>
          </Grid>

          <Typography
            variant="h3"
            style={{
              marginLeft: "1.5rem",
              width: "100%",
              fontWeight: "bold",
              fontSize: "15px",
            }}
          >
            {t("registerForm.bankData")}
          </Typography>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="bankAccount"
              label={t("registerForm.bankAccount")}
              name="bankAccount"
              value={formData.bankAccount}
              onChange={handleInputChange}
              InputProps={{ style: { borderRadius: "55px" } }}
              helperText={t(fieldErrors.bankAccount)}
              error={Boolean(fieldErrors.bankAccount)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="bankAlias"
              label={t("registerForm.bankAlias")}
              name="bankAlias"
              value={formData.bankAlias}
              onChange={handleInputChange}
              InputProps={{ style: { borderRadius: "55px" } }}
              helperText={t(fieldErrors.bankAlias)}
              error={Boolean(fieldErrors.bankAlias)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="cryptoWallet"
              label={t("registerForm.cryptoWallet")}
              name="cryptoWallet"
              value={formData.cryptoWallet}
              onChange={handleInputChange}
              InputProps={{ style: { borderRadius: "55px" } }}
              helperText={t(fieldErrors.cryptoWallet)}
              error={Boolean(fieldErrors.cryptoWallet)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="cryptoNet"
              label={t("registerForm.cryptoNet")}
              name="cryptoNet"
              value={formData.cryptoNet}
              onChange={handleInputChange}
              InputProps={{ style: { borderRadius: "55px" } }}
              helperText={t(fieldErrors.cryptoNet)}
              error={Boolean(fieldErrors.cryptoNet)}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.acceptTerms}
                  onChange={handleCheckboxChange}
                  name="acceptTerms"
                />
              }
              label={
                <Trans
                  i18nKey="registerForm.acceptTerms"
                  components={{
                    anchor: (
                      <a
                        target="_blank"
                        href="https://drinksify.io/terms"
                        rel="noreferrer"
                      >
                        terminos y condiciones
                      </a>
                    ),
                    anchor2: (
                      <a
                        target="_blank"
                        href="https://drinksify.io/policy"
                        rel="noreferrer"
                      >
                        politica de privacidad
                      </a>
                    ),
                  }}
                />
              }
            />
          </Grid>

          <Grid item xs={12} container justifyContent="flex-start">
            <Button
              type="submit"
              variant="contained"
              className={"btn-gradient"}
            >
              {t("registerForm.registerButton")}
            </Button>
          </Grid>
        </Grid>
      </form>

      <ErrorDialog
        open={!!error}
        onClose={() => setError(null)}
        alertTitle={t("registerForm.registrationError")}
        alertMessage={error}
        dark={false}
      />
    </Container>
  );
};

export default RegisterForm;
