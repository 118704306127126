import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Hero from "../components/Hero";
import HomeBenefits from "../components/HomeBenefits";
import CellarSlider from "../components/CellarSlider";
import HomeInfo1 from "../components/HomeInfo1";
import CallToActionRegister from "../components/CallToActionRegister";
import HomeMarketplace from "../components/HomeMarketplace";
import HomeTutorial from "../components/HomeTutorial";
import HomeInfo2 from "../components/HomeInfo2";
import HomeBenefitsInfo from "../components/HomeBenefitsInfo";
import CallToActionSubscribe from "../components/CallToActionSubscribe";
import FooterComponent from "../components/FooterComponent";
import backgroundImage from "../../assets/images/FONDO-HOME.png";
import SuccessDialog from "../components/SuccessDialog";
import { useTranslation } from "react-i18next";
import HomeNews from "../components/HomeNews";

const HomeView = () => {
  const { t } = useTranslation();
  const [modalMessage, setModalMessage] = useState(null);
  const location = useLocation();
  const { hash, key } = useLocation();

  useEffect(() => {
    if (hash) {
      const targetElement = document.getElementById(hash.substring(1));
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [key, hash]);

  useEffect(() => {
    if (location.state && location.state.showMessage) {
      setModalMessage(location.state.message);
    }
  }, [location]);

  return (
    <div>
      <Hero
        title={"TOKENIZED PREMIUM WINES & SPIRITS"}
        subtitle={t("home.hero.title")}
        backgroundImage={backgroundImage}
      />
      <HomeBenefits />
      <CellarSlider />
      <HomeInfo1 />
      <CallToActionRegister />
      <HomeMarketplace />
      <HomeTutorial />
      <HomeInfo2 />
      <HomeBenefitsInfo />
      <CallToActionSubscribe />
      <HomeNews />
      <FooterComponent />
      <SuccessDialog
        open={!!modalMessage}
        onClose={() => setModalMessage(null)}
        alertTitle={"Notificación"}
        alertMessage={modalMessage}
        dark={true}
      />
    </div>
  );
};

export default HomeView;
